<template>
  <v-app>
    <v-app-bar
      app
      absolute
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
      />
      <v-spacer></v-spacer>
      {{ title }}
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list>
        <v-list-item-group
          v-model="navimenu"
        >
          <v-list-item
            v-for="(item,index) in menuitems"
            :key="'navigation'+index"
            :to="item.link"
          >
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data(){
    return {
      title:'インフォム管理システム',
      drawer:true,
      navimenu:null,
      menuitems:[
        {
          title:'トップページ',
          link:'/',
        },
        {
          title:'顧客情報',
          link:'/Customers',
        }
      ]
    }
  }
}
</script>
<template>
  <v-container
    fluid
  >
    <v-data-iterator
      :items="items"
    >
      <template v-slot:header>
        <v-toolbar>
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-invereted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="検索"
          />
          <v-spacer></v-spacer>
          <v-select
            v-model="sort"
            flat
            solo-inverted
            hide-details
            :items="keys"
            prepend-inner-icon="mdi-magnify"
            label="並び替え"
          />
          <v-spacer></v-spacer>
          <v-btn-toggle
              v-model="sortDesc"
              mandatory
            >
              <v-btn
                large
                depressed
                :value="false"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                large
                depressed
                :value="true"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
        </v-toolbar>
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
          >
            <v-card>
              <v-card-title>
                {{ item.name }}
              </v-card-title>

              <v-divider></v-divider>

              <v-list dense>
                <v-list-item
                  v-for="(key, index) in filteredKeys"
                  :key="index"
                >
                  <v-list-item-content :class="{ 'blue--text': sortBy === key }">
                    {{ key }}:
                  </v-list-item-content>
                  <v-list-item-content
                    class="align-end"
                    :class="{ 'blue--text': sortBy === key }"
                  >
                    {{ item[key.toLowerCase()] }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>

          </v-col>
        </v-row>

      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
export default {
  props:['id'],
  data(){
    return{
      itemsPerPageArray: [4, 8, 12],
      search:null,
      filter: {},
      sort:'name',
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      keys:[
        'name',
        'incname',
        'in',
        'address',
        'email',

      ],
      items:[],
    }
  },
  computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== 'Name')
      },
    },
    methods: {
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
    },
    created(){
      this.items.push(this.$route.params.id);
      console.log(this.items);
    }
}
</script>
<template>
  <v-card>
    <v-card-title>
      Nutrition
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      @click:row="test"
    ></v-data-table>
  </v-card>
</template>

<script>
export default {
  data(){
    return{
      search:null,
      headers:[
        {
          text:"お名前",
          align:'start',
          value:'name',
        },
        {
          text:"法人名",
          value:'incname'
        },
        {
          text:"住所",
          value:'address'
        },
        {
          text:"メールアドレス",
          value:'email'
        },
        {
          text:"登録元",
          value:'in'
        }
      ],
      items:[
        {
          name:'テスト1',
          in:"修理",
          address:"北海道札幌市南区真駒内泉町2丁目2-29",
          email:'cc@infom.jp'
        },
        {
          name:'テスト2',
          in:"LP",
          incname:'○○株式会社',
          email:'bb@infom.jp'
        },
        {
          name:'テスト3',
          email:'aa@infom.jp'
        },
      ]

    }
  },
  methods:{
    test(value){
      // alert(value.name);
      this.$router.push({
        name:'CustomerDetails',
        params:{
          id:value,
        }
      })
      console.log(value)
    }
  }
}
</script>
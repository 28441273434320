<template>
  <v-container
    fluid
  >
    ホーム
  </v-container>
</template>
<script>
export default {
  data(){
    return{
      search:null,
      sort:null,

    }
  }
}
</script>